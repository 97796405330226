// This script fills the `div.cart` element (e.g. /site/snippets/header.php).
// It also handles adding items, update items and deleting items from the cart.
// The instance of Cart is stored as a global variabel (`window.cart`).


import SlimSelect from "slim-select";
import {InitQuantityInputs} from "./components/number-input";

export class Cart {
  constructor(element) {
    this.lang = document.querySelector('html').lang;

    this.element = element;
    this.cartDetailsElement = document.querySelector('.details-cart');
    this.mobileCartToggler = document.querySelector('.toggle-cart.show-xs');
    this.countElement = document.querySelector('.details-cart .cart-count');
    this.mobileCountElement = document.querySelector('.toggle-cart.show-xs .cart-count');
    this.siteTitle = document.querySelector('.site-title.hide-xs');

    // this object will be used to store the cart data we are loading from the api.
    this.data = {};

    // store language variables
    // this should be the same as in /site/languages/en.php
    this.i18n = {
      'cart': 'cart',
      'cart.empty': 'cart is empty.',
      'cart.hide': 'Hide Cart',
      'cart.item.remove': 'Remove',
      'cart.included-vat': 'incl. VAT',
      'cart.vat-included': 'incl. VAT',
      'cart.quantity': 'quantity',
      'cart.items': "Items",
      'cart.quantity-in-cart': 'in cart',
      'cart.change-quantity': 'Change quantity',
      'cart.price': 'price',
      'cart.sum': 'sum',
      'cart.shipping': 'shipping',
      'cart.product': 'Product',
      'cart.free-shipping': 'free',
      'cart.calculated-at-checkout': 'calculated at check out',
      'cart.to-checkout': 'checkout',
      'cart.order.review': 'items',
    };

    // overwrite default language variables
    if (this.lang === 'fr') {
      this.i18n = {
        'cart': 'panier',
        'cart.empty': 'Le panier est vide',
        'cart.hide': 'Fermer le Panier',
        'cart.item.remove': 'Supprimer',
        'cart.included-vat': 'TVA incl.',
        'cart.vat-included': 'TVA incl.',
        'cart.quantity': 'Quantité',
        'cart.items': "Articles",
        'cart.quantity-in-cart': 'Dans le panier',
        'cart.change-quantity': 'Modifier la quantité',
        'cart.price': 'Prix',
        'cart.sum': 'Total',
        'cart.shipping': 'Livraison',
        'cart.product': 'Produit',
        'cart.free-shipping': 'Gratuit',
        'cart.calculated-at-checkout': 'Livraison calculée au paiement',
        'cart.to-checkout': 'Commander',
        'cart.order.review': 'Commande',
      };
    }
    this.mobileCartToggler?.addEventListener('click', (event) => {
      event.stopPropagation();
      this.mobileCartToggler.classList.add('box--grey');
      const menuContainer = document.querySelector('.header-nav.menu-container');
      menuContainer.classList.add('cart-is-open');
      this.element.classList.add("open");
      menuContainer.classList.add('show');
    });

    this.cartDetailsElement?.addEventListener('click', (event) => {
      event.stopPropagation();
      this.element.classList.add("open");
      this.mobileCartToggler?.classList.add('box--grey');
      this.siteTitle.classList.add('hide');
      const menuContainer = document.querySelector('.header-nav.menu-container');
      menuContainer.classList.add('cart-is-open');
      /*const menuContainers = document.querySelectorAll('.menu-container');
      menuContainers.forEach((menu) =>{
        menu.classList.remove('show');
      });*/

    });

    document.addEventListener('click', (e) => {
      this.element.classList.remove("open");
      this.siteTitle.classList.remove('hide');
      this.mobileCartToggler?.classList.remove('box--grey');
      const menuContainer = document.querySelector('.header-nav.menu-container');
      menuContainer.classList.remove('cart-is-open');
      /*
            this.cartDetailsElement.classList.remove("color-red");
      */
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.element.classList.remove("open");
        const menuContainer = document.querySelector('.header-nav.menu-container');
        menuContainer.classList.remove('cart-is-open');
      }
    });

    // initially update & load cart data from api
    this.request("cart");
  }

  // helper method to handle different api request
  // the api endpoint is defined in /site/plugins/site/api.php
  request(endpoint, method = 'GET', data = null) {
    const {lang} = this;

    return fetch(`/api/shop/${endpoint}`, {
      method, headers: {
        'Content-Type': 'application/json', 'x-language': lang,
      }, body: method !== 'GET' ? JSON.stringify(data) : null,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'ok') {
          // store response data to data object.
          this.data = json.data;
          this.updateHTML();
          this.updateCount();
          console.log(this.data);
        } else {
          alert(json.message);
        }
        return json;
      });
  }

  updateCount() {
    const {data} = this;
    if (this.countElement) {
      if (data.quantity === 0) {
        this.countElement.innerText = '';
        if(this.mobileCartToggler){
          this.mobileCountElement.innerText = '';
        }

      } else {
        this.countElement.innerText = `(${data.quantity})`;
        if(this.mobileCartToggler) {
          this.mobileCountElement.innerText = `(${data.quantity})`;
        }
      }
    }
  }

  updateHTML() {
    const {data, i18n} = this;

    function createQuantitySelect(item) {
      const ariaLabel = `${item.quantity} ${i18n['cart.quantity-in-cart']}. ${i18n['cart.change-quantity']}.`;
      const id = item.title.replace(' ', '').toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      console.log(id);
      /*let html = `<select  class="item-select" id="select-${id}" data-key="${item.key}" aria-label="${ariaLabel}">`;
      for (let i = 0; i <= item.maxAmount; i += 1) {
        html += `
          <option ${i === item.quantity ? 'selected' : ''}>
            ${i}
          </option>
        `;
      }*/
      /*html += '</select>';*/
      let html = `<div data-key="${item.key}" data-value="${item.quantity}" data-max="${item.maxAmount}"class="quantity"><input type="number"></div>`;
      return html;
    }

    function createCartItem(item) {
      return `
            <div class="t-item">
                <div class="i-name">
                    <a href="${item.url}">
                        <p class="txt-left">${item.title}
                        ${item.variant ? `<span>${item.variant}</span></p>` : '</p>'}
                    </a>
                </div>
                <div class="i-info">
                    <div class="i-quantity t-row">
                        <h4>${i18n['cart.quantity']}</h4>
                        ${createQuantitySelect(item)}
                    </div>
                    <div class="i-price t-row">
                        <h4>${i18n['cart.price']}</h4>
                        ${item.price}
                    </div>
                </div>
         </div>
      `;
    }

    function createCartItems(items) {
      let html = '';
      items.forEach((item) => {
        html += createCartItem(item);
      });
      return html;
    }

    function createTaxRates(taxRates) {
      let html = '';
      taxRates.forEach((taxRate) => {
        html += `
                <div class="tax t-row">
                    <h4>${i18n['cart.included-vat']} (${taxRate.taxRate}%)</h4> ${taxRate.sum}
                </div>
        `;
      });
      return html;
    }

    if (data.quantity === 0) {
      this.element.innerHTML = `
        <div class="cart-header">
           <p>${i18n['cart']} <i class="icon-cross close-cart"></i></p>
        </div>
        <div class="cart-logo">
        <h2>startseite</h2>
        </div>

        <div class="cart-info">
            <p class="txt-left">${i18n['cart.empty']}</p>
        </div>
      `;
    } else {
      this.element.innerHTML = `
        ${(this.element.dataset.variant == 'checkout') ? `<h2 class="cart-title text-m">${i18n['cart.order.review']}</h2>` : ''}
        <div class="cart-header">
           <p>${i18n['cart']} <i class="icon-cross close-cart"></i></p>
        </div>
        <div class="cart-logo">
        <h2>startseite</h2>
        </div>
        <div class="table">
            <div class="t-header">
                <h4 class="txt-left">${i18n['cart.items']}</h4>
            </div>
            <div class="t-items">
                    ${createCartItems(data.items)}
            </div>
            <div class="t-foot">
                  <h4 class="cart-shipping">${i18n['cart.shipping']}</h4>
                  <h4 class="txt-left cart-shipping">${i18n['cart.calculated-at-checkout']}</h4>
                  <div class="sum checkout-shipping t-row">
                    <h4>${i18n['cart.shipping']}</h4> ${data.shipping}
                  </div>
                  <div class="sum t-row">
                    <h4>${i18n['cart.sum']}</h4> ${data.sum}
                  </div>
                  ${createTaxRates(data.taxRates)}
            </div>
        </div>
        ${(this.element.dataset.variant !== 'checkout') ? `<div class="check-out--btn-container "><a href="${data.checkoutUrl}" class="button txt-center box--grey--outline box--black-hover width-100">${i18n['cart.to-checkout']}</a></div>` : ''}
      `;

      // UPDATE QUANTITY
      this.element.querySelectorAll("select").forEach((selectElement, i) => {
        selectElement.addEventListener('change', (event,) => {
          var isChecked = this.SetBillingAsShipping?.checked;
          const {key} = event.target.dataset;
          const quantity = event.target.value;
          console.log(event.target);
          this.update(key, quantity, null);
        });
      });
    }


    // CLOSE CART BUTTON
    this.element.querySelector(".close-cart").addEventListener('click', (event) => {
      event.stopPropagation();
      this.element.classList.remove("open");
      this.siteTitle.classList.remove('hide');
      this.mobileCartToggler?.classList.remove('box--grey');
      const menuContainer = document.querySelector('.header-nav.menu-container');
      menuContainer.classList.remove('cart-is-open');

    });

    this.element.querySelectorAll("select").forEach((selectElement, i) => {
      const id = '#' + selectElement.getAttribute('id');
      console.log('ok');
      console.log(id);
      var Selecter = [];
      Selecter.push(new SlimSelect({
        select: id,
        allowDeselect: false,
        closeOnSelect: true,
        showSearch: false,
        onChange: (info) => {

        }
      }));
    });

    InitQuantityInputs();
    this.element.querySelectorAll(".quantity input").forEach((numberInput) =>{
      numberInput.addEventListener('change', () =>{
        var isChecked = this.SetBillingAsShipping?.checked;
        const {key} = numberInput.parentElement.dataset;
        const quantity = numberInput.value;
        console.log("key "+key);
        console.log("quantity "+quantity);
        this.update(key, quantity, null);

      });
    });
  }

  add(id, quantity = 1) {
    this.element.classList.add('-loading');
    return this.request('cart', 'POST', {
      id, quantity,
    }).finally(() => {
      this.element.classList.add("open");
      this.element.classList.remove('-loading');
    });
  }

  update(id, quantity = 1, shippingCountry) {
    this.element.classList.add('-loading');
    return this.request('cart', 'PATCH', {
      id, quantity, shippingCountry,
    }).finally(() => {
      this.element.classList.remove('-loading');
    });
  }

  updateShippingCountry(shippingCountry) {
    this.element.classList.add('-loading');
    console.log('update');
    return this.request('test', 'PATCH', {
      shippingCountry,
    }).finally(() => {
      this.element.classList.remove('-loading');
    });
  }
}

const cartElement = document.getElementById('cart');
if (cartElement) {
  // Store the instance of Cart as a global variable so other scripts can make use of Cart methods.
  window.cart = new Cart(cartElement);
}

