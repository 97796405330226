__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import { onLoading } from "./_modules/website";
import { loadPage } from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import {Cart} from "./_modules/cart";
import {Shop} from './_modules/templates/shop';
import {Checkout} from "./_modules/templates/checkout";
import {Product} from "./_modules/templates/product";
import {ProductVariants} from "./_modules/templates/product-variants";
import {ConditionalFields} from "./_modules/templates/conditional-fields";
import {Select} from "./_modules/components/select";
import {Swiper} from "./_modules/components/select";
import './_modules/components/number-input';
import './_modules/components/header';


document.addEventListener("DOMContentLoaded", () => {
  onLoading();
  loadPage();
  ConditionalFields();
  Select();
});
