export class Shop {
  constructor(element) {
    this.element = element;
    this.productGrid = element.querySelector('.product-grid');
    this.filters = document.querySelectorAll(".categories-menu a");
    this.sort = document.querySelectorAll(".sort-menu a");
    this.hideSold = document.querySelector(".hide-menu a");
    this.main = document.querySelector('main');


    this.init();
  }

  init() {
    /* Handle sorting by price */
    var sort = "default";
    var sold = false;




    this.sort.forEach((filter) => {
      filter.addEventListener("click", (e) => {
        this.productGrid.classList.add("-loading");
        if (!filter.classList.contains("active")) {
          this.sort.forEach((filter) => {
            filter.classList.remove('active');
          });
          filter.classList.add('active');
          const filterType = "filter";
          const filterName = document.querySelector(".categories-menu a.active").dataset.filter;
          sort = filter.dataset.sort;
          this.getProducts(filterType, filterName, sort, sold);
        }

      });
    });

    /* Handle category selection */
    this.filters.forEach((filter) => {
      filter.addEventListener("click", (e) => {
        if (!filter.classList.contains("active")) {
          this.productGrid.classList.add("-loading");
          this.filters.forEach((filter) => {
            filter.classList.remove('active');
          });
          filter.classList.add('active');
          const filterType = filter.dataset.type;
          const filterName = filter.dataset.filter;
          this.getProducts(filterType, filterName, sort, sold);
          if(filterName == "all"){
            this.productGrid.dataset.category = filterName;
          }else{
            this.productGrid.dataset.category = filterName;
          }
        }
      });
    });

    /* Handle sold items */

    this.hideSold.addEventListener("click", (e) => {
      const filterType = document.querySelector(".categories-menu a.active").dataset.type;
      const filterName = document.querySelector(".categories-menu a.active").dataset.filter;
      this.hideSold.classList.toggle('active');
      if(this.hideSold.classList.contains('active')) {
        sold = true;
        this.getProducts(filterType, filterName, sort, sold);
      }else{
        sold = false;
        this.getProducts(filterType, filterName, sort, sold);
      }
    });


  }

  request(endpoint, method = 'GET | POST', data = null) {
    const {lang} = this;

    return fetch(`/api/shop/${endpoint}`, {
      method, headers: {
        'Content-Type': 'application/json', 'x-language': lang,
      }, body: method !== 'GET' ? JSON.stringify(data) : null,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === 'ok') {
          // store response data to data object.
          console.log(json);
          this.productGrid.innerHTML = json.data;
        } else {
          alert(json.message);
        }
        return json;
      });
  }

  getProducts(filterType, filterName, sort, sold) {
    const $this = this;
    const $main = this.main;
    setTimeout(function (){
      $this.request("categories", "POST", {
        filterType, filterName, sort, sold
      }).finally(() => {
        $main.scrollTop = 0;
        $this.productGrid.classList.remove('-loading');
      });
    }, 100);
  }

}

document.querySelectorAll('.shop').forEach((shopElement) => new Shop(shopElement));
