import Swiper, {Controller, EffectFade, Lazy, Navigation, Pagination, Thumbs} from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';

export const Slideshow = () => {

  var loop = true;

  const thumbContainer = document.querySelector('.thumbs-container');

  if (thumbContainer.dataset.length <= 5) {
    var slidePerViewMd = thumbContainer.dataset.length;
  } else {
    slidePerViewMd = 5;
  }

  if (thumbContainer.dataset.length <= 3) {
    var slidePerViewSm = thumbContainer.dataset.length;
  } else {
    slidePerViewSm = 3;
  }

  thumbContainer.style.setProperty('--slidePerViewMd', slidePerViewMd);
  thumbContainer.style.setProperty('--slidePerViewSm', slidePerViewSm);

  var thumbsSwiper = new Swiper('.thumbs-container', {
    spaceBetween: 10,
    centeredSlides: false,
    freeMode: true,
    allowTouchMove: true,
    watchSlidesProgress: true,
    slideToClickedSlide: false,
    watchSlidesVisibility: true,
    breakpoints: {
      0:{
        slidesPerView: slidePerViewSm,
      },
      1024: {
        slidesPerView: slidePerViewMd,
      },
    },
    on: {
      touchEnd: function (s, e) {
        let range = 5;
        let diff = s.touches.diff = s.isHorizontal() ? s.touches.currentX - s.touches.startX : s.touches.currentY
          - s.touches.startY
        if (diff < range || diff > -range) s.allowClick = true;
      }
    },
  });

  var swiperP = new Swiper('.swiper-container-p', {
    modules: [Navigation, Lazy, EffectFade, Thumbs, Pagination, Controller],
    cssMode: false,
    direction: 'horizontal',
    allowTouchMove: true,
    followFinger: true,
    preloadImages: false,
    loopedSlides: slidePerViewMd,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1,
      loadOnTransitionStart: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    loop: true,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    speed: 300,
    thumbs: {
      swiper: thumbsSwiper,
    },
  });

  var thumbs = document.querySelector(".thumbs-container");
  var main = document.querySelector(".swiper-container-p");

  main.swiper.thumbs.swiper = thumbs.swiper;


  window.addEventListener('resize', function (){

    var firstVisible = document.querySelector('.thumbs-container .swiper-slide-visible');
    var firstVisibleIndex = firstVisible.dataset.slideIndex;


  });

}

