const toggler = document.querySelectorAll('.toggler');
const body = document.body;

window.addEventListener("resize", (e) => {
  const menuContainers = document.querySelectorAll('.menu-container');
  menuContainers.forEach((menu) =>{
    menu.classList.remove('show');
  });
  toggler.forEach((toggler) => {
    toggler.classList.remove('box--grey');
  });
});

  body.addEventListener("click", (e) => {
    if (e.target.classList.contains('toggler')) {
      e.preventDefault();
      const el = e.target;
      const menuContainers = document.querySelectorAll('.menu-container');
      menuContainers.forEach((menu) =>{
        menu.classList.remove('show');
      });
      showMenu(el);
    }else{
      hideMenu();
    }
  });


function showMenu(toggler) {
  const target = document.getElementsByClassName(toggler.dataset.target)[0];
  console.log(target);
  target.classList.add('show');
  toggler.classList.add('box--grey');
}

function hideMenu() {
  const menusContainer = document.querySelectorAll('.menu-container');
  menusContainer.forEach((container) => {
    container.classList.remove('show');
    container.classList.remove('cart-is-open');
    toggler.forEach((toggler) => {
      toggler.classList.remove('box--grey');
    });
  });
}
