import {Slideshow} from "../components/swiper";

export class Product {
  constructor(element) {
    this.buttonAddToCart = element.querySelector('button[data-action="add-to-cart"]');
    this.cart = window.cart;
    this.relatedItems = element.querySelectorAll('.product--item-related');
    this.displayBox = element.querySelector('.product--display-box');

    this.relatedItems.forEach((item) =>{
      item.addEventListener('mouseenter', () =>{
        const thumb = "<img class='lazyload' src='"+item.dataset.thumb+"'>";
        this.displayBox.innerHTML = thumb;
      });
      item.addEventListener('mouseleave', () =>{
        this.displayBox.innerHTML = null;
      });
    });

    this.buttonAddToCart?.addEventListener('click', async (event) => {
      event.stopPropagation();
      event.target.classList.add('-loading');
      await window.cart.add(element.dataset.id);
      window.cart.element.setAttribute('open', '');
      window.cart.element.scrollIntoView({
        behavior: 'smooth',
      });
      event.target.classList.remove('-loading');
      if( window.innerWidth < 1024) {
        const menuContainer = document.querySelector('.header-nav.menu-container');
        menuContainer.classList.add('cart-is-open');
        menuContainer.classList.add('show');
      }
    });

    this.initSwiper();

  }

  initSwiper(){
    Slideshow();
  }


}



document.querySelectorAll('.product').forEach((productElement) => new Product(productElement));
